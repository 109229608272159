<template>
  <div class="page">
    <vue-confirm-dialog />
    <v-row class="text-center intro">
      <v-col cols="12" class="pb-0">
        <v-img :src="require('../assets/logo-black.svg')" class="" contain height="200" />
      </v-col>
    </v-row>

    <v-container>
      <div v-if="terms.length">
        <v-row class="text-center">
          <v-col class="mb-4" v-if="category">
            <h1 class="display-1 font-weight-bold mb-5" v-text="category.name">
              Přihláška na příměstský tábor
            </h1>
            <div v-html="category.description" />
          </v-col>
        </v-row>
        <div v-if="step == 1">
          <v-row class="text-center">
            <v-col md="4" sm="12" v-for="(term, key) in terms" :key="key" class="mb-4 d-block" style="flex: none;">

              <div class="turnus">
                <h3 class="font-weight-bold mb-5" v-html="term.name.replace('(', '<br />(')"></h3>
                <p>
                  <strong>Kapacita: </strong> {{ term.capacity }} dětí<br />
                  <strong>Cena: </strong> {{ term.price }},- Kč
                </p>

                <v-btn v-if="!term.qty" :disabled="children.length >= 4" @click="addChild(term.id, term)">Přidat</v-btn>
                <div v-if="term.qty >= 1">
                  <v-btn @click="removeChild(term.id, term)">-</v-btn>
                  <span v-if="term.qty" style="padding: 0 8px;">V košíku: <strong>{{ term.qty }}</strong></span>
                  <v-btn :disabled="children.length >= 4 || term.qty >= term.capacity"
                    @click="addChild(term.id, term)">+</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <v-btn color="primary" :disabled="!hasChildren" @click="next()">Pokračovat</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row class="text-center">
          <v-col class="mb-4">
            <h1 class="display-1 font-weight-bold mb-5">
              V současnou chvíli nejsou k dispozici žádné produkty. Buď byly vyprodány, nebo ještě nezačal jejich prodej.
            </h1>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="step == 2" class="text-center pb-10">
        <v-col cols="12">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <form @submit.prevent="onSubmit">
              <h2>Zákazník <span v-if="customer.name">{{ customer.name }}</span></h2>
              <p><i>Pole označená hvězdičkou jsou povinná</i></p>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.firstName" label="Jméno *" :maxlength="50" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.lastName" label="Příjmení *" :maxlength="50" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <v-text-field v-model="customer.email" label="E-mail *" :maxlength="50" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.address.street" label="Ulice *" :maxlength="150" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.address.town" label="Město *" :maxlength="100" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.address.zip" label="PSČ *" :maxlength="6" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider rules="required|presenceString" v-slot="{ errors }">
                <v-text-field v-model="customer.phone" label="Telefon *" :maxlength="20" />
                <span class="validationMessage">{{ errors[0] }}</span>
              </ValidationProvider>
              <v-checkbox v-model="customer.isCompany" label="Nakupuji na firmu"></v-checkbox>
              <v-row v-if="customer.isCompany">
                <v-col cols="6">
                  <v-text-field v-model="customer.companyName" label="Společnost" :maxlength="200" />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="customer.companyCRN" label="IČ" :maxlength="20" />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="customer.companyVAT" label="DIČ" :maxlength="20" />
                </v-col>
              </v-row>

              <v-checkbox v-model="gdprAgree" :rules="[v => !!v || 'Váš souhlas je nutný k odeslání formuláře']"
                class="mt-5">
                <template v-slot:label>
                  <div>
                    Souhlasím se zpracováním
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a @click.stop v-on="on" href="/gdpr.pdf" target="_blank">osobních údajů</a>
                      </template>
                      Otevře se v novém okně
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>

              <p>Odesláním formuláře souhlasíte s <a href="/vop.pdf" target="_blank">obchodními podmínkami</a>. </p>
              <p style="font-weight: bold; color:red;">Údaje dítěte vyplníte až po zaplacení. Nezavírejte prosím Váš
                prohlížeč.</p>

              <p>Celková cena: {{ totalPrice }}</p>

              <v-btn color="primary" :disabled="invalid || !gdprAgree || isLoading" @click="onSubmit">Závazně objednat a
                zaplatit</v-btn>
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import PhoneNumber from 'awesome-phonenumber';
import moment from 'moment'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'


const baseUrl = process.env.VUE_APP_BASE_URL
const projectId = process.env.VUE_APP_PROJECT_ID
const productCategoryId = process.env.VUE_APP_PRODUCT_CATEGORY_ID

const payment = {
  paymentMethodId: process.env.VUE_APP_PAYMENT_METHOD_ID,
  shippingMethodId: process.env.VUE_APP_SHIPPING_METHOD_ID,
  eshopId: process.env.VUE_APP_ESHOP_ID
}

extend('phoneNumber', value => {
  let phone = new PhoneNumber(value)
  if (phone.isValid()) {
    return true
  }
  return 'Zadejte prosím telefon ve správném tvaru včetně převolby (+420)'
});

extend('presenceString', value => {
  if (value.length > 1) {
    return true
  }
  return 'Prosím zadejte alespoň 2 znaky'
})

extend('email', {
  ...email,
  message: 'Nevalidni mail'
})

extend('required', {
  ...required,
  message: 'Toto pole je povinné'
})

export default {
  name: 'RegisterForm',

  components: {
    ValidationProvider, ValidationObserver, extend
  },

  data: () => ({
    isLoading: false,
    step: 1,
    category: {},
    customer: {
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      address: {
        street: null,
        town: null,
        zip: null
      },
      isCompany: false,
      companyName: null,
      companyCRN: null,
      companyVAT: null,
    },
    children: [],
    insuranceAgencies: [],
    terms: [],
    day: [...Array(31).keys()].map(i => i + 1),
    month: [...Array(12).keys()].map(i => i + 1),
    year: [...Array(10).keys()].map(i => i + (new Date().getFullYear() - 16)),
    gdprAgree: false,
    currencyFormatter: new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    maxCharsInTextarea: 80
  }),
  mounted() {
    this.fetchData()
  },
  computed: {
    hasChildren: function () {
      return (this.children.length > 0)
    },
    totalPrice: function () {            
      const ids = this.children.map(child => child.term);      
      const sum = this.terms.filter((term) => ids.includes(term.id)).map(term => term.price * term.qty).reduce((a, b) => a + b, 0);      
      return this.currencyFormatter.format(sum);
    }
  },
  methods: {
    next() {
      this.step = 2;
    },
    fetchData() {      
      this.isLoading = true;
      axios.all([
        axios.get(`${baseUrl}/codelist/HealthInsurance`),
        axios.get(`${baseUrl}/project/${projectId}/products/${productCategoryId}`),
        axios.get(`${baseUrl}/project/${projectId}/productcategories`)
      ])
        .then(axios.spread((responseHealth, responseTerms, responseCategory) => {
          this.insuranceAgencies = responseHealth.data.map(agency => ({
            id: agency.id,
            name: agency.name
          }))          
          this.category = responseCategory.data[0];
          this.terms = responseTerms.data.map(term => ({
            id: term.id,
            price: term.amountWithVat,
            capacity: term.inStock,
            name: term.name
          }))
        }))
        .catch((error) => {
          this.handleNetworkError(error)
        }).finally(() => {
          this.isLoading = false;
        })
    },
    removeChild(termId, term) {
      for (let i in this.children) {
        if (this.children[i].term === termId) {
          this.children.splice(i, 1)
          term.qty--;
        }
      }
    },
    addChild(termId, term) {
      if (term.hasOwnProperty('qty')) {
        term.qty++;
      } else {
        term.qty = 1;
      }

      this.children.push({
        name: null,
        nickname: null,
        birthday: {
          day: null,
          month: null,
          year: null
        },
        insurance: null,
        address: null,
        parents: [
          {
            email: null,
            name: null,
            phone: null,
          },
          {
            email: null,
            name: null,
            phone: null,
          },
        ],
        info: {
          health: "",
          other: "",
        },
        handover: "yes",
        pickup: "yes",
        term: termId,
      })

    },
    deleteChild(key) {
      this.$confirm({
        title: 'Smazat dite',
        message: `Opravdu chcete smazat ${this.children[key].name || 'dítě'}?`,
        button: {
          yes: 'OK',
          no: 'Zrušit'
        },
        callback: confirm => {
          if (confirm === true) {
            if (key < this.children.length) {
              this.children.splice(key, 1)
            }
          }
        }
      })
    },
    handleNetworkError(error) {
      if (error.response.status == 422) {
        this.$confirm({
          title: 'Upozornění',
          message: `Kapacita turnusu je již v tuto chvíli vyprodána! Skutečná rezervace probíhá až po odeslání objednávky.`,
          button: {
            yes: 'Zavřít'
          }
        })
      } else {
        this.$confirm({
          title: 'Upozornění ',
          message: `Chyba komunikace se serverem: ${error}`,
          button: {
            yes: 'OK'
          }
        })
      }

    },
    async onSubmit() {
      const success = await this.$refs.form.validate() && this.gdprAgree
      if (!success) {
        return
      }
      const items = [];
      this.children.forEach(child => {
        child.birthday = moment(`${child.birthday.year}-${('0' + child.birthday.month).slice(-2)}-${('0' + child.birthday.day).slice(-2)}`)
          .format('YYYY-MM-DD')
        const customData = child
        customData.type = 'SummerCamp'
        items.push({ productId: child.term, qty: 1, customData: customData })
      })

      const formData = {
        ...payment,
        customer: {
          ...this.customer
        },
        items: items,
        gdprAgree: this.gdprAgree
      }

      this.isLoading = true;

      this.$nextTick(() => {
        this.$refs.form.reset()
      })

      axios.post(`${baseUrl}/shoppingcart`, formData)
        .then((res) => {
          console.log(res.data.paymentUrl)
          window.location.replace(decodeURI(res.data.paymentUrl));
        })
        .catch((error) => {
          console.log(error);
          this.handleNetworkError(error)
        }).finally(() => {
          this.isLoading = false;
        })
    },
    mockForm() {
      this.customer.firstName = "Jiri"
      this.customer.lastName = "Spacek"
      this.customer.phone = "12345678"
      this.customer.address.street = "Nad Ovcirnou 5"
      this.customer.address.town = "Praha"
      this.customer.address.zip = "156 00"
      this.customer.isCompany = true
      this.customer.companyCRN = "123456"
      this.customer.companyVAT = "CZ123456"
      this.customer.email = "josef@seznam.cz"
      this.children[0].name = "Pepa Dite"
      this.children[0].nickname = "Pepik"
      this.children[0].birthday.day = 10
      this.children[0].birthday.month = 5
      this.children[0].birthday.year = 2015
      this.children[0].insurance = "08b1bdf1-fd27-4966-b6ae-7b8cbfe58753"
      this.children[0].address.address = "Adresa"
      this.children[0].parents[0].email = "zastupce@gmail.com"
      this.children[0].parents[0].name = "Zdenek Zastupce"
      this.children[0].parents[0].phone = "1234567"
      this.children[0].parents[1].email = "zastupkyne@gmail.com"
      this.children[0].parents[1].name = "Katerina Zastupcova"
      this.children[0].parents[1].phone = "1234567"
      this.children[0].info.health = "Zdravi zdravi zdravi"
      this.children[0].info.other = "Dite je plache"
      this.children[0].handover = "yes"
      this.children[0].pickup = "no"
      this.children[0].term = "d2663953-a28c-4d59-826a-e50015146edc"
      this.children[1].name = "Dagmar Ditetova"
      this.children[1].nickname = "Dasa"
      this.children[1].birthday.day = 5
      this.children[1].birthday.month = 9
      this.children[1].birthday.year = 2016
      this.children[1].insurance = "29182a6b-0aeb-4d1a-baad-b321da9c7a90"
      this.children[1].address.address = "Adresa"
      this.children[1].parents[0].email = "zastupce@gmail.com"
      this.children[1].parents[0].name = "Petr Zastupce"
      this.children[1].parents[0].phone = "1234567"
      this.children[1].parents[1].email = "zastupkyne@gmail.com"
      this.children[1].parents[1].name = "Mirka Zastupcova"
      this.children[1].parents[1].phone = "12345678"
      this.children[1].info.health = "Zdravi zdravi zdravi zdravi"
      this.children[1].info.other = "Dite je zvidave"
      this.children[1].handover = "no"
      this.children[1].pickup = "yes"
      this.children[1].term = "09b434ba-f26b-4d28-801f-7431b34024cf"
      this.gdprAgree = false
    },
  },
}
</script>

<style>
.intro {
  background: #201d1e;
  margin-bottom: 50px !important;
}

.container {
  max-width: 1000px !important;
}

.v-input--radio-group legend.v-label {
  font-size: 16px !important;
  margin-bottom: 10px;
}

.child {
  padding: 40px 40px 10px 40px;
  border: 1px solid #aaa;
  margin: 20px auto 40px auto;
  width: 100%;
  clear: both;
  float: none;
  display: block;
  position: relative;
}

.child .delete {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.v-textarea textarea {
  height: 100px;
}

.validationMessage {
  color: red;
  font-size: 0.9rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ECECEC;
}

.turnus {
  border: 1px solid #201d1e;
  margin: 10px;
  padding: 1rem;
  box-sizing: border-box;
}
</style>
