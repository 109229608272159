<template>
  <div>
    <v-app>
      <v-main>
        <RegisterForm v-if="!orderId"/>
        <ChildrenDetails :eshop-id="eshopId" :editable="editable" :already-paid="alreadyPaid" :order-id="orderId" v-if="orderId"/>
      </v-main>
      <div class="dark-background container--fluid">
        <div class="container white--text copyright dark-background">
          <div class="row justify-space-between d-flex"><span> Zoologická zahrada hl. m. Prahy, U Trojského zámku 120/3, 171 00 Praha 7 </span><span>
            <span style="padding: 0 10px; color: #fff;"><button style="color: #fff; text-decoration: underline;" @click="cookies">Nastavení soukromí</button> |</span>
            <a
              href="https://zoopraha.cz" target="_blank" class="white--text">Zoo Praha</a></span></div>
        </div>
      </div>
      <cookie lang="cs" primary-color="#201d1e" ref="cookie" />
    </v-app>
  </div>
</template>

<style>
  .dark-background {
    background: #201d1e;
    color: #fff;
    padding: 0.75rem;
    font-size: 95%;
  }
  a.white--text {
    color: #fff;
    float: right;
  }
  .d-flex {
    line-height: 20px;
    display: flex;
    justify-content: space-between;
  }

  .v-application .primary {
    background: #201d1e !important;
  }
</style>

<script>
import RegisterForm from "@/components/RegisterForm";
import ChildrenDetails from "@/components/ChildrenDetails";
import Cookie from "@/components/Cookie2022";

export default {
  name: 'App',

  components: {
    RegisterForm,
    ChildrenDetails,
    Cookie
  },

  methods: {
    cookies() {
      this.$refs.cookie.open();
    }
  },

  data: () => ({
    orderId: null,
    editable: true,
    alreadyPaid: false,
    eshopId: null
  }),

  mounted() {
    let urlString = String(window.location);

    /*let newUrl = urlString.replace('https://zoo-tabory.vercel.app/', 'https://tabory.zoopraha.cz/');
    window.location = newUrl;*/    
    console.log(window.location.search);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlString.includes('payment/feedback')) {
      let parts = urlString.split('/');
      this.eshopId = parts[5];
      this.orderId = urlParams.get('ORDERNUMBER');
    } else if (urlString.includes('prihlaska/')) {
      let parts = urlString.split('/');
      this.alreadyPaid = true;
      this.eshopId = parts[4];
      this.orderId = parts[5];
      this.editable = parts[6] === 'editable';
    }
  }
};
</script>
